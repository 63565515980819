<template>
  <v-container fluid class="ma-0 pa-0 background">
    <v-card
      class="mx-auto elevation-0 px-2 pb-3 mb-4 background"
      flat
      v-if="!isDashTester"
    >
      <UpgradeBanner
        v-if="
          (!firstSyncUsersStatus.status ||
            firstSyncUsersStatus.status == 'finished') &&
          !missingScopesSuite.length &&
          !isDashTester
        "
      />

      <!-- SCOPE ALERT  -->
      <Alert
        v-if="missingScopesSuite.length && !isDashTester"
        class="ma-3 mb-0"
        :prominent="!$vuetify.breakpoint.mobile"
        color="warning"
        outlined
      >
        <h4 class="mb-1 alert-text-color">
          <strong>{{ $t("scopesAlert.alertTitle") }}</strong>
        </h4>
        <p
          class="pa-0 ma-0 text-body-2 alert-text-color"
          v-html="scopeMessage1"
        ></p>
        <v-divider class="my-2 info" style="opacity: 0.22"></v-divider>
        <v-row class="ma-0 pa-0" no-gutters align="center">
          <v-col lg="9" sm="6" xs="12" md="8" class="pa-0 grow text-body-2">
            <span
              class="alert-text-color"
              v-html="$t('scopesAlert.alertMessage4')"
            >
            </span>
          </v-col>

          <v-col
            lg="3"
            sm="6"
            xs="12"
            md="4"
            class="pa-0 mt-3 shrink d-flex justify-end"
          >
            <v-btn
              text
              small
              color="warning"
              :href="linkDocAboutScopes"
              target="_blank"
              class="mr-2 text-body-2 font-weight-medium"
            >
              {{ $t("action.knowMore") }}
            </v-btn>
            <v-btn
              v-if="isAdmin"
              text
              small
              outlined
              style="border: 1px #f39c12 solid"
              color="warning"
              :href="missingScopesLink"
              target="_blank"
              class="ml-auto text-body-2 font-weight-medium"
            >
              {{ $t("action.allowAccess") }}
            </v-btn>
          </v-col>
        </v-row>
      </Alert>

      <!-- SYNC USERS ALERT  -->
      <Alert
        v-if="firstSyncUsersStatus.status == 'started' && !isDashTester"
        class="mt-4 mb-4 mb-0"
        :prominent="!$vuetify.breakpoint.mobile"
        color="info"
        outlined
      >
        <br />
        <h4 v-if="isAdmin">{{ $t("syncUsers.syncUsers") }}:</h4>
        <v-progress-linear
          color="primary"
          class="my-5 white--text"
          :value="progress"
          height="25"
          rounded
        >
          <strong>
            {{
              `${$t("syncUsers.synchronizingUsers")}: ${
                firstSyncUsersStatus.total_users_created
              } ${$t("common.of")} ${firstSyncUsersStatus.total_users}`
            }}
          </strong>
        </v-progress-linear>
      </Alert>

      <!-- ON BOARDING DIALOG BY PRODUCT -->
      <!-- CURRENT DASHBOARD -->
      <v-row
        :class="`align-center ma-0 py-4 px-0 background`"
        align-content="center"
        justify-lg="start"
        justify-xs="center"
      >
        <v-col
          cols="12"
          xs="12"
          md="3"
          lg="3"
          xl="2"
          v-for="(card, i) in cards"
          :key="i"
        >
          <v-card
            hover
            id="v-step-0"
            @click.stop="
              showOnboardingModuleOrSetPath(card.key, card.path, card.title)
            "
            class="pt-6 pb-3 card"
            height="255"
            width="100%"
            min-width="200"
            outlined
            :disabled="loading"
          >
            <div v-if="card.type == 'icon'">
              <v-icon
                size="65"
                :class="`d-flex justify-center align-start mb-3`"
                color="accent"
                >{{ card.icon }}</v-icon
              >
            </div>

            <div :class="`d-flex justify-center align-start mb-1`">
              <Logo
                no-margins
                width="65"
                :height="isProduct(card.name) ? '55' : '65'"
                :product="card.name"
              />
            </div>

            <h3 class="text-center px-2" v-if="card.title">
              <span>{{ card.title }}</span>
              <span v-if="card.beta">
                <v-chip
                  color="blue"
                  dark
                  :title="$t('common.availableIndefinitely')"
                  x-small
                  class="ml-2 font-weight-medium"
                  >Beta
                </v-chip>
              </span>
            </h3>

            <v-card-subtitle class="pb-0 text-center px-4">{{
              card.description
            }}</v-card-subtitle>
          </v-card>
        </v-col>
      </v-row>
    </v-card>

    <!-- NEW DASHBOARD -->
    <div class="ma-0 px-9 newDashBackground" v-else>
      <v-row class="my-6" style="position: relative">
        <v-col cols="12" class="px-0">
          <span class="greetings font-montserrat my-4">
            {{
              `👋 ${$t("newDashboard.hello")}, ${
                currentUser.first_name || currentUser.name
              }`
            }}</span
          >
          <CTACall
            v-if="$vuetify.breakpoint.mdAndUp"
            class="controlCTAFixed"
            :action="
              !isSmallClient && showScheduleMeeting ? 'meeting' : 'controlCTA'
            "
          />

          <v-tabs
            v-model="homeTab"
            color="#5B36C3"
            background-color="#f4f3f3"
            class="mt-5"
          >
            <v-tab
              key="dashboard"
              class="text-none tab-name"
              active-class="tab-font-weight"
            >
              <v-icon size="20" class="mr-2"> ph-house</v-icon>
              {{ $t("sidebar.home") }}
            </v-tab>
            <v-tab
              key="tech-diagnosis"
              class="text-none tab-name"
              :style="`${
                homeTab == 1 ? 'font-weight: 600' : 'font-weight: 500'
              }`"
            >
              <v-icon size="20" class="mr-2"> ph-cell-signal-full</v-icon>
              {{ $t("sidebar.techDiagnosis") }}</v-tab
            >
            <v-tabs-items v-model="homeTab">
              <v-tab-item key="dashboard" style="background-color: #f4f3f3">
                <v-row
                  class="d-flex flex-column justify-center align-center"
                  dense
                  no-gutters
                  v-if="isDashCardsEmpty"
                >
                  <Alert
                    class="mt-4 mb-0"
                    color="info"
                    outlined
                    style="width: 100%; height: 62px"
                  >
                    <span
                      v-html="$t('newDashboard.emptyStateAlert')"
                      class="tab-name text-wrap"
                    >
                    </span>
                  </Alert>
                </v-row>

                <div class="grid-container mt-4">
                  <!-- INDICATORS -->
                  <IndicatorsCard
                    v-for="(card, i) in dashCards"
                    :key="i"
                    :card="card"
                    :isEmptyState="isDashCardsEmpty"
                  />

                  <!-- TABLE -->
                  <UsersTableMetrics class="metrics-table" />

                  <!-- TODO: PIE CHART -->
                  <!-- SHORTCUTS -->
                  <v-card
                    max-height="320"
                    elevation="0"
                    class="cards shortcuts"
                  >
                    <h2 class="px-4 pt-4 pb-2 shortcut-title">
                      {{ $t("common.shortcut", { plural: "s" }) }}
                    </h2>
                    <v-card-text class="py-0 px-3">
                      <v-list class="pa-0 py-0" dense>
                        <v-list-item
                          v-for="(item, i) in dashShortcuts"
                          :key="i"
                          @click="setPath(item.path)"
                          class="pa-0 mb-2 pb-3 pt-2 mt-0"
                          v-show="item.show"
                          :style="`${
                            i === dashShortcuts.length - 1
                              ? ''
                              : 'border-bottom: 1px dashed #C8C8C8 !important;'
                          }`"
                        >
                          <v-list-item-icon
                            class="mr-2 item-icon d-flex justify-center align-center my-0"
                          >
                            <v-icon size="27" v-if="item.icon" color="#3498db">
                              {{ item.icon }}
                            </v-icon>
                            <img v-else :src="item.logo" height="22" class="" />
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title
                              class="item-title text-decoration-underline text-wrap"
                              >{{ $t(item.name) }}</v-list-item-title
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </div>
              </v-tab-item>

              <!-- TECH DIAGNOSIS -->
              <v-tab-item
                key="tech-diagnosis"
                style="background-color: #f4f3f3"
              >
                <TechDiagnosis />
              </v-tab-item>
            </v-tabs-items>
          </v-tabs>
        </v-col>
        <v-col cols="3" style="margin-top: 102px"> </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import Alert from "@/components/base/Alert.vue";
import Logo from "@/components/base/Logo";
import UpgradeBanner from "@/components/billing/upgrade/UpgradeBanner.vue";
import IndicatorsCard from "@/components/home/IndicatorsCard.vue";
import CTACall from "@/components/main/newDashboard/CTACall.vue";
import TechDiagnosis from "@/components/main/newDashboard/TechDiagnosis.vue";
import UsersTableMetrics from "@/components/main/newDashboard/UsersTableMetrics.vue";
import { isProduct, productsInPlan } from "@/helpers/services/products";
import {
  goToControl,
  redirectToTheCommercialManagerGoogleCalendar,
} from "@/helpers/services/utils";
import {
  googleAppsPainelLink,
  googleScopesDocLink,
  microsoftAppsPainelLink,
  microsoftScopesDocLink,
} from "@/helpers/variables/links";
import {
  cardsName,
  modules,
  modulesDetails,
} from "@/helpers/variables/modules";
import { getAnalytics, logEvent } from "firebase/analytics";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Home",
  components: {
    Logo,
    Alert,
    UpgradeBanner,
    CTACall,
    IndicatorsCard,
    UsersTableMetrics,
    TechDiagnosis,
  },
  data: () => ({
    modulesDetails,
    modules,
    cardsName,
    homeTab: "",
    zeroPercent: 0,
  }),

  computed: {
    ...mapGetters([
      "isReseller",
      "missingScopesSuite",
      "company",
      "hasConectaTrack",
      "isAdmin",
      "hasContractedPlans",
      "loading",
      "firstSyncUsersStatus",
      "isDashTester",
      "showScheduleMeeting",
      "isSmallClient",
      "currentUser",
      "hasIntermediaryPartner",
      "hasConectaSupport",
      "users",
      "controlUsers",
      "usersInShiftPercentage",
      "usersNotInShift",
      "usersInActiveVacation",
      "isCompanyMicrosoft",
    ]),

    scopeMessage1() {
      const providerName = this.isCompanyMicrosoft ? "Microsoft" : "Google";
      return this.$t("scopesAlert.alertMessage1", { provider: providerName });
    },

    linkDocAboutScopes() {
      return this.isCompanyMicrosoft
        ? microsoftScopesDocLink
        : googleScopesDocLink;
    },

    isMobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    cards() {
      return [
        {
          name: modules.users,
          title: this.$t(cardsName.users),
          description: this.$t(modulesDetails.users.short_description),
          path: "/users",
          key: modules.users,
        },
        {
          name: modules.workspace_diagnosis,
          title: this.$t(cardsName.workspace_diagnosis),
          description: this.$t(
            modulesDetails.workspace_diagnosis.short_description
          ),
          path: "/workspace-checklist",
          key: modules.workspace_diagnosis,
          icon: "ph-list-checks",
          hidden: this.isCompanyMicrosoft,
        },
        {
          name: modules.inventory,
          title: this.$t(cardsName.inventory),
          description: this.$t(modulesDetails.inventory.short_description),
          path: "/inventory",
          key: modules.inventory,
          beta: true,
          hidden: this.isCompanyMicrosoft,
        },
        {
          name: modules.companyProfile,
          title: this.$t(cardsName.company),
          description: this.$t(
            modulesDetails.company_profile.short_description
          ),
          path: "/company",
          key: modules.companyProfile,
        },
        {
          name: modules.manage_admins,
          title: this.$t(cardsName.manage_admins),
          description: this.$t(modulesDetails.manage_admins.short_description),
          path: "/admin",
          key: modules.manage_admins,
        },
        {
          name: modules.reseller,
          title: this.$t(cardsName.reseller),
          description: this.$t(modulesDetails.reseller.short_description),
          path: "/reseller/clients",
          hidden: !this.isReseller,
          key: modules.reseller,
        },
        {
          name: modules.track,
          title: this.$t(cardsName.track),
          description: this.$t(modulesDetails.conecta_track.short_description),
          path: "/track/users",
          hidden: !this.hasConectaTrack,
          key: modules.track,
        },
        {
          name: modules.sign,
          title: this.$t(cardsName.sign),
          description: this.$t(modulesDetails.conecta_sign.short_description),
          path: "/sign",
          key: modules.sign,
        },
        {
          name: modules.control,
          title: this.$t(cardsName.control),
          description: this.$t(
            modulesDetails.conecta_control.short_description
          ),
          path: null,
          key: modules.control,
        },
        {
          name: modules.reports,
          title: this.$t(cardsName.reports),
          description: this.$t(modulesDetails.reports.short_description),
          path: "/reports",
          key: modules.reports,
        },
        {
          name: modules.billing,
          title: this.$t(cardsName.billing),
          description: this.$t(modulesDetails.billing.short_description),
          hidden: !this.hasContractedPlans,
          path: "/billing/plans",
          key: modules.billing,
        },
        {
          name: modules.contact_us,
          title: this.$t(cardsName.contact_us),
          description: this.$t(modulesDetails.contact_us.short_description),
          path: "/contact",
          key: modules.contact_us,
        },
      ].filter((item) => !item.hidden);
    },

    dashCards() {
      return [
        {
          icon: "ph-regular ph-article-medium",
          title: this.$t("newDashboard.dashCards.title.signature"),
          description: this.$t("newDashboard.dashCards.description.signature"),
          subDescription: this.$t(
            "newDashboard.dashCards.subDescription.signature",
            { usersNumber: this.usersHasNotSingnatureEmail.length }
          ),
          percentValues: this.usersHasAppliedSingnaturePercent,
          isEmpty: this.usersHasAppliedSingnaturePercent == this.zeroPercent,
          hasToUpgradeText: this.$t(
            "newDashboard.dashCards.hasToUpgrade.signature"
          ),
          emptyActionText: "applySignature",
          emptyAction: () =>
            this.$router.push("/sign/email-signature/templates"),
          emptyStateDefaultValue: "87%",
          product: productsInPlan.conecta_sign,
        },
        {
          icon: "ph-regular ph-chat-text",
          title: this.$t("newDashboard.dashCards.title.vacationMessage"),
          description: this.$t(
            "newDashboard.dashCards.description.vacationMessage"
          ),
          usersCount: String(this.usersHasVacationMessage.length),
          users: this.usersHasVacationMessage,
          isEmpty: !this.usersHasVacationMessage.length,
          emptyActionText: "applyVacationMessage",
          emptyAction: () => this.$router.push("/sign/vacation-message"),
          emptyStateDefaultValue: "5",
          product: productsInPlan.conecta_suite,
        },
        {
          icon: "ph-regular ph-island",
          title: this.$t("newDashboard.dashCards.title.vacationActive"),
          description: this.$t(
            "newDashboard.dashCards.description.vacationActive"
          ),
          usersCount: String(this.usersInActiveVacation.length),
          users: this.suiteUsersInVacationActive,
          isEmpty: !this.usersInActiveVacation.length,
          hasToUpgradeText: this.$t(
            "newDashboard.dashCards.hasToUpgrade.vacationActive"
          ),
          emptyActionText: "configVacationMessage",
          emptyAction: () => this.goToControl("users"),
          emptyStateDefaultValue: "20",
          product: productsInPlan.conecta_control,
        },
        {
          icon: "ph-regular ph-bag-simple",
          title: this.$t("newDashboard.dashCards.title.controlShifts"),
          description: this.$t(
            "newDashboard.dashCards.description.controlShifts"
          ),
          subDescription: this.$t(
            "newDashboard.dashCards.subDescription.controlShifts",
            { usersNumber: this.usersNotInShift.length }
          ),
          percentValues: String(this.usersInShiftPercentage),
          isEmpty: this.usersInShiftPercentage === this.zeroPercent,
          hasToUpgradeText: this.$t(
            "newDashboard.dashCards.hasToUpgrade.controlShifts"
          ),
          emptyActionText: "createShifts",
          emptyAction: () => this.goToControl("groups"),
          emptyStateDefaultValue: "14%",
          product: productsInPlan.conecta_control,
        },
      ];
    },

    isDashCardsEmpty() {
      return (
        !this.usersHasNotSingnatureEmail.length &&
        !this.usersHasVacationMessage.length &&
        !this.suiteUsersInVacationActive.length &&
        !this.usersNotInShift.length
      );
    },

    dashShortcuts() {
      return [
        {
          logo: require("@/assets/appslogos/icons/icon-control.png"),
          name: "sidebar.conectaControl",
          path: "conecta_control",
          show: true,
        },
        {
          icon: "ph-article-medium",
          name: "sidebar.emailSignatures",
          path: "/sign/email-signature/templates",
          show: true,
        },
        {
          icon: "ph-headset",
          name: "newDashboard.support",
          path: "/contact",
          show: this.hasConectaSupport,
        },
        {
          icon: "ph-gift",
          name: "sidebar.plans",
          path: "/billing/upgrade",
          show: !this.hasIntermediaryPartner,
        },
      ];
    },

    missingScopesLink() {
      return this.isCompanyMicrosoft
        ? microsoftAppsPainelLink
        : googleAppsPainelLink;
    },

    progress() {
      const { total_users, total_users_created } = this.firstSyncUsersStatus;
      return (total_users_created / total_users) * 100;
    },

    usersHasVacationMessage() {
      return this.users.filter((user) => {
        return !!user.applied_vacation_key;
      });
    },

    usersHasNotSingnatureEmail() {
      return this.users.filter((user) => !user.applied_signature_key);
    },

    usersHasAppliedSingnaturePercent() {
      if (!this.users.length) return 0;
      const missingUsers =
        this.users.length - this.usersHasNotSingnatureEmail.length;
      return ((missingUsers / this.users.length) * 100).toFixed(0);
    },
    showShortcuts() {
      return window.innerWidth >= 1440;
    },
    suiteUsersInVacationActive() {
      const controlUsers = this.usersInActiveVacation.map((user) => user.email);
      return this.users.filter((user) => controlUsers.includes(user.email));
    },
  },

  methods: {
    ...mapActions(["getControlUsersMetrics"]),
    isProduct,
    goToControl,

    scheduleMeeting() {
      const analytics = getAnalytics();
      const { main_domain } = this.company;
      const clicked_where = "opened new dashboard card";
      const clicked_from = "Botão de agendar reunião (Dash)";

      logEvent(analytics, "new dashboard card", {
        main_domain,
        clicked_where,
        date: moment().format("DD/MM/YYYY"),
      });

      redirectToTheCommercialManagerGoogleCalendar(clicked_from);
    },

    showOnboardingModuleOrSetPath(code, path, title) {
      const { main_domain } = this.company;

      const analytics = getAnalytics();
      logEvent(analytics, "navigated_by", {
        code,
        path,
        title,
        navigation_by: "HomePage",
        main_domain,
      });

      // Mesmo sem permissão ao control, há o redirecionamento.
      if (code === "conecta_control") {
        goToControl();
        return;
      }

      this.setPath(path);
    },

    setPath(path) {
      if (path === "conecta_control") {
        goToControl();
        return;
      }
      this.$router.push({ path: path });
    },

    async getControlMetrics() {
      if (!this.isDashTester || this.controlUsers.length) return;
      await this.getControlUsersMetrics();
    },

    controlCTA() {
      this.scheduleMeeting();
    },
    resizeCardsByResolutionScreen() {
      const windowWidth = window.innerWidth;
      const figmaDesktopResolutionWidth = 1440;
      const desktopsMoreBiggerThanFigma = 1530;
      if (windowWidth <= figmaDesktopResolutionWidth) return "191px";
      if (windowWidth > desktopsMoreBiggerThanFigma) return "240px";
      return "191px";
    },
  },
  watch: {
    users() {
      this.getControlMetrics();
    },
  },
  beforeMount() {
    this.getControlMetrics();
    this.resizeCardsByResolutionScreen();
  },

  created() {
    window.addEventListener(
      "resizeShortcutsCard",
      this.resizeCardsByResolutionScreen
    );
  },
  destroyed() {
    window.removeEventListener(
      "resizeShortcutsCard",
      this.resizeCardsByResolutionScreen
    );
  },
};
</script>
<style scoped>
.greetings {
  font-size: 1.38rem;
  font-weight: medium;
}

.newDashBackground {
  background-color: #f4f3f3;
}

.font-montserrat {
  font-family: "Montserrat", sans-serif !important;
}

.controlCTAFixed {
  position: absolute;
  top: 15px;
  right: 0px;
  z-index: 1;
}

.item-icon {
  width: 40.05px !important;
  height: 39px !important;
  padding: 8.43px 9.49px 8.43px 8px;
  gap: 10.54px;
  border-radius: 12.65px;
  background: #3498db1a;
}
.item-title {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.3339366316795349px;
  text-align: left;
  color: #3498db;
}
.tab-name {
  font-family: Poppins;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.28568235039711px;
  text-align: left;
  font-weight: 500;
}
.tab-font-weight {
  font-weight: 600;
}

.shortcut-title {
  font-size: 14px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

.indicators {
  grid-area: indicators;
}

.metrics-table {
  grid-area: metrics;
  grid-row-start: 2;
  grid-row-end: 3;
  grid-column-start: 1;
  grid-column-end: 5;
}

.shortcuts {
  grid-area: shortcuts;
  grid-row-start: 1;
  grid-row-end: 3;
}

.pie-charts {
  grid-area: charts;
}

.grid-container {
  display: grid;
  gap: 13px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-auto-columns: 1fr;
}

@media only screen and (max-width: 1440px) {
  .shortcuts {
    grid-area: shortcuts;
    grid-row: 2;
    grid-column-start: 1;
    grid-column-end: 5;
  }

  .metrics-table {
    grid-area: metrics;
    grid-row-start: 3;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 5;
  }
}

.alert-text-color {
  color: #73510d;
}
</style>
